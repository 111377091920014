import { sheets_v4 } from "googleapis";
import { usePBSSheetData } from "./hooks";
import { formatNumber, renderImage } from "../utils";
import { Fragment, useMemo, useRef } from "react";
import Frame from "./Frame";

const commonCellStyle ={ padding: '4px 20px' };

const playerProps = (isBreakWin: boolean, ballsPocketedRow: any, rows: any, middleColumnIdx: number) => ({
  "Balls Pocketed": {
    header: (data: any) => isBreakWin ? 'DEFENSE' : ballsPocketedRow?.[middleColumnIdx]?.formattedValue,
    value: undefined,
    render: (key: string, idx: number, elem: any) => (
      <td style={{ ...commonCellStyle, }} key={`${key}${idx}`} className="text-yellow">
        {elem.value}
      </td>
    ),
  },
  first: {
    header: (data: any) => {
      if (!data.hasOwnProperty('second')) {
        return isBreakWin ? rows?.[22]?.[middleColumnIdx].formattedValue : 'Total';
      }
      return 'Set 1';
    },
    value: 0,
    render: (key: string, idx: number, elem: any) => {
      return <td style={{ ...commonCellStyle, }} key={`${key}${idx}`}>{elem.value}</td>
    },
  },
  second: {
    header: (data: any) => 'Set 2',
    value: 0,
    render: (key: string, idx: number, elem: any) => (
      <td style={{ ...commonCellStyle, }} key={`${key}${idx}`}>{elem.value}</td>
    ),
  },
  third: {
    header: (data: any) => 'Set 3',
    value: 0,
    render: (key: string, idx: number, elem: any) => (
      <td style={{ ...commonCellStyle, }} key={`${key}${idx}`}>{elem.value}</td>
    ),
  },
  fourth: {
    header: (data: any) => 'Set 4',
    value: 0,
    render: (key: string, idx: number, elem: any) => (
      <td style={{ ...commonCellStyle, }} key={`${key}${idx}`}>{elem.value}</td>
    ),
  },
  fifth: {
    header: (data: any) => 'Set 5',
    value: 0,
    render: (key: string, idx: number, elem: any) => (
      <td style={{ ...commonCellStyle, }} key={`${key}${idx}`}>{elem.value}</td>
    ),
  },
  match: {
    header: (data: any) => {
      if (!data.hasOwnProperty('second')) {
        return null;
      }
      return <span className="text-yellow">Match</span>;
    },
    value: 0,
    render: (key: string, idx: number, elem: any) => {
      let value = elem.value;
      // if this is in column position 3 (3rd column, index 2 of array)
      if (idx === 2) {
        value = '';
      }
      return <td style={ value ? { padding: '4px 20px', } : {}} className="text-yellow" key={`${key}${idx}`}>{value}</td>
    },
  },
});

const BallsPocketedTable = () => {
  const { numOfSets, rows } = usePBSSheetData();
  const viewData = {
    name: undefined,
    players: [],
  };
  // const ballsPocketedData = rows.find((rowData) =>
  //   rowData.find((elem) => elem.formattedValue === "Balls Pocketed"),
  // );
  const ballsPocketedData = rows?.[17];
  if (!ballsPocketedData?.[3]?.formattedValue) {
    return null;
  }
  // build up data structure
  const player1Name = rows?.[1]?.[0]?.formattedValue;
  const player2Name = rows?.[1]?.[numOfSets + 2]?.formattedValue;
  let dataColumnCount = 0;
  let isBreakWin = false;
  let middleColumnIdx = 0;
  if (ballsPocketedData && player1Name && player2Name) {
    // subtract 1 for the middle column,
    // divide by 2 cause there is 2 players
    // @ts-ignore
    middleColumnIdx = (ballsPocketedData.length - 1) / 2;
    const alternateData = rows?.[22];
    isBreakWin =
      rows?.[9]?.[middleColumnIdx]?.formattedValue === "Break And Win" && !!alternateData;
    dataColumnCount = (ballsPocketedData.length - 1) / 2;
    const focusedDataRow = isBreakWin ? alternateData : ballsPocketedData;
    const matchName = rows?.[0]?.[middleColumnIdx]?.formattedValue;
    (viewData as any).name ??= matchName;
    (viewData as any).players[0] ??= playerProps(isBreakWin, ballsPocketedData, rows, middleColumnIdx);
    (viewData as any).players[1] ??= playerProps(isBreakWin, ballsPocketedData, rows, middleColumnIdx);
    (viewData as any).players[0]["Balls Pocketed"].value = player1Name;
    (viewData as any).players[1]["Balls Pocketed"].value = player2Name;
    (viewData as any).players[0]["first"].value =
      focusedDataRow?.[dataColumnCount - 1]?.formattedValue;
    (viewData as any).players[1]["first"].value =
      focusedDataRow?.[dataColumnCount + 1]?.formattedValue;

    if (
      dataColumnCount > 2 &&
      (ballsPocketedData?.[dataColumnCount - 2]?.formattedValue !== undefined ||
        ballsPocketedData?.[dataColumnCount + 2]?.formattedValue !== undefined)
    ) {
      (viewData as any).players[0]["match"].value =
        focusedDataRow?.[dataColumnCount - 3]?.formattedValue;
      (viewData as any).players[1]["match"].value =
        focusedDataRow?.[dataColumnCount + 3]?.formattedValue;
      (viewData as any).players[0]["second"].value =
        focusedDataRow?.[dataColumnCount - 2]?.formattedValue;
      (viewData as any).players[1]["second"].value =
        focusedDataRow?.[dataColumnCount + 2]?.formattedValue;
    } else {
      delete (viewData as any).players[0]["second"];
      delete (viewData as any).players[1]["second"];
    }

    if (
      dataColumnCount > 3 &&
      (focusedDataRow?.[dataColumnCount - 3]?.formattedValue !== undefined ||
        focusedDataRow?.[dataColumnCount + 3]?.formattedValue !== undefined)
    ) {
      (viewData as any).players[0]["match"].value =
        focusedDataRow?.[dataColumnCount - 4]?.formattedValue;
      (viewData as any).players[1]["match"].value =
        focusedDataRow?.[dataColumnCount + 4]?.formattedValue;
      (viewData as any).players[0]["third"].value =
        focusedDataRow?.[dataColumnCount - 3]?.formattedValue;
      (viewData as any).players[1]["third"].value =
        focusedDataRow?.[dataColumnCount + 3]?.formattedValue;
    } else {
      delete (viewData as any).players[0]["third"];
      delete (viewData as any).players[1]["third"];
    }

    if (
      dataColumnCount > 4 &&
      (focusedDataRow?.[dataColumnCount - 4]?.formattedValue !== undefined ||
        focusedDataRow?.[dataColumnCount + 4]?.formattedValue !== undefined)
    ) {
      (viewData as any).players[0]["match"].value =
        focusedDataRow?.[dataColumnCount - 5]?.formattedValue;
      (viewData as any).players[1]["match"].value =
        focusedDataRow?.[dataColumnCount + 5]?.formattedValue;
      (viewData as any).players[0]["fourth"].value =
        focusedDataRow?.[dataColumnCount - 4]?.formattedValue;
      (viewData as any).players[1]["fourth"].value =
        focusedDataRow?.[dataColumnCount + 4]?.formattedValue;
    } else {
      delete (viewData as any).players[0]["fourth"];
      delete (viewData as any).players[1]["fourth"];
    }

    if (
      dataColumnCount > 5 &&
      (focusedDataRow?.[dataColumnCount - 5]?.formattedValue !== undefined ||
        focusedDataRow?.[dataColumnCount + 5]?.formattedValue !== undefined)
    ) {
      (viewData as any).players[0]["match"].value =
        focusedDataRow?.[dataColumnCount - 6]?.formattedValue;
      (viewData as any).players[1]["match"].value =
        focusedDataRow?.[dataColumnCount + 6]?.formattedValue;
      (viewData as any).players[0]["fifth"].value =
        focusedDataRow?.[dataColumnCount - 5]?.formattedValue;
      (viewData as any).players[1]["fifth"].value =
        focusedDataRow?.[dataColumnCount + 5]?.formattedValue;
    } else {
      delete (viewData as any).players[0]["fifth"];
      delete (viewData as any).players[1]["fifth"];
    }

  }

  const firstPlayer = viewData.players[0];
  const view = (
    <Frame>
      <table style={{ display: "block", width: "auto", }}>
        <tbody>
          <tr className="bg-grey">
            {Object.keys(firstPlayer).map((val, idx) => {
              const columnHeader = (playerProps(isBreakWin, ballsPocketedData, rows, middleColumnIdx) as any)[val].header(firstPlayer)
              return (
                <td style={ columnHeader ? { ...commonCellStyle, }: {}} key={`playerProps${idx}`}>
                  {(playerProps(isBreakWin, ballsPocketedData, rows, middleColumnIdx) as any)[val].header(firstPlayer)}
                </td>
              );
            })}
          </tr>
          {viewData.players.map((data: any, idx) => (
            <tr key={`playerData${idx}`} className="bg-black">
              {Object.keys(data).map((key: string, idx: number) =>
                data[key].render(key, idx, data[key]),
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </Frame>
  );
  return view;
};

export default BallsPocketedTable;
