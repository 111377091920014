import { sheets_v4 } from "googleapis";
import { usePBSSheetData } from "./hooks";
import { formatNumber, renderImage } from "../utils";
import { Fragment, useMemo, useRef } from "react";
import Frame from "./Frame";

type Widget = "all" | "break" | "break-table" | "offense" | "defense";

const playerProps = (isBreakWin: boolean) =>
  isBreakWin
    ? {
        OFFENSE: {
          header: (
            rows: any,
            playerData: any,
            middleIndex: number,
            propName: string,
          ) => {
            return "OFFENSE";
          },
          value: undefined,
          render: (key: string, idx: number, elem: any) => (
            <td style={{ padding: '4px 20px', }} key={`${key}${idx}`} className="text-yellow">
              {elem.value}
            </td>
          ),
        },
        "Missed Shots": {
          header: (
            rows: any,
            playerData: any,
            middleIndex: number,
            propName: string,
          ) => {
            return rows[playerData.rowIndex][middleIndex].formattedValue;
          },
          rowIndex: 16,
          value: 0,
          render: (key: string, idx: number, elem: any) => (
            <td style={{ padding: '4px 20px', }} key={`${key}${idx}`}>{elem.value}</td>
          ),
        },
        "Maximum Run": {
          header: (
            rows: any,
            playerData: any,
            middleIndex: number,
            propName: string,
          ) => {
            return rows[playerData.rowIndex][middleIndex].formattedValue;
          },
          rowIndex: 17,
          value: 0,
          render: (key: string, idx: number, elem: any) => (
            <td style={{ padding: '4px 20px', }} key={`${key}${idx}`}>{elem.value}</td>
          ),
        },
        "Led in Ball Count": {
          header: (
            rows: any,
            playerData: any,
            middleIndex: number,
            propName: string,
          ) => {
            return rows[playerData.rowIndex][middleIndex].formattedValue;
          },
          rowIndex: 18,
          value: 0,
          render: (key: string, idx: number, elem: any) => (
            <td style={{ padding: '4px 20px', }} key={`${key}${idx}`}>{elem.value}</td>
          ),
        },
      }
    : {
        BREAK: {
          header: (
            rows: any,
            playerData: any,
            middleIndex: number,
            propName: string,
          ) => {
            return "BREAK";
          },
          value: undefined,
          render: (key: string, idx: number, elem: any) => (
            <td style={{ padding: '4px 20px', }} key={`${key}${idx}`} className="text-yellow">
              {elem.value}
            </td>
          ),
        },
        Success: {
          header: (
            rows: any,
            playerData: any,
            middleIndex: number,
            propName: string,
          ) => {
            return propName;
            return rows[playerData.rowIndex][middleIndex].formattedValue;
          },
          rowIndex: 9,
          value: 0,
          render: (key: string, idx: number, elem: any) => (
            <td style={{ padding: '4px 20px', }} key={`${key}${idx}`}>{elem.value}</td>
          ),
        },
        Pocketed: {
          header: (
            rows: any,
            playerData: any,
            middleIndex: number,
            propName: string,
          ) => {
            return propName;
            return rows[playerData.rowIndex][middleIndex].formattedValue;
          },
          rowIndex: 11,
          value: 0,
          render: (key: string, idx: number, elem: any) => (
            <td style={{ padding: '4px 20px', }} key={`${key}${idx}`}>{elem.value}</td>
          ),
        },
        Foul: {
          header: (
            rows: any,
            playerData: any,
            middleIndex: number,
            propName: string,
          ) => {
            return propName;
            return rows[playerData.rowIndex][middleIndex].formattedValue;
          },
          rowIndex: 10,
          value: 0,
          render: (key: string, idx: number, elem: any) => (
            <td style={{ padding: '4px 20px', }} key={`${key}${idx}`}>{elem.value}</td>
          ),
        },
      };

const Stats = ({ widget }: { widget?: Widget }) => {
  const { numOfSets, rows } = usePBSSheetData();
  const viewData = {
    name: undefined,
    players: [],
  };
  // build up data structure
  if (!rows?.[0]) {
    return null;
  }
  const middleColumnIdx = (rows[0].length - 1) / 2;
  const isBreakWin =
    rows?.[9]?.[middleColumnIdx]?.formattedValue === "Break And Win";
  const matchName = rows?.[0]?.[middleColumnIdx]?.formattedValue;
  const player1Name = rows?.[1]?.[0]?.formattedValue;
  const player2Name = rows?.[1]?.[numOfSets + 2]?.formattedValue;
  if (player1Name && player2Name && matchName) {
    const player1Props = playerProps(isBreakWin);
    const player2Props = playerProps(isBreakWin);
    const [key0, key1, key2, key3] = Object.keys(player1Props);
    const idx1 = (player1Props as any)[key1].rowIndex;
    const idx2 = (player1Props as any)[key2].rowIndex;
    const idx3 = (player1Props as any)[key3].rowIndex;
    (viewData as any).name ??= matchName;
    (viewData as any).players[0] ??= player1Props;
    (viewData as any).players[1] ??= player2Props;
    (viewData as any).players[0][key0].value = player1Name;
    (viewData as any).players[1][key0].value = player2Name;
    (viewData as any).players[0][key1].value = rows?.[idx1]
      ?.slice(0, middleColumnIdx)
      ?.find((elem) => elem.formattedValue)?.formattedValue;
    (viewData as any).players[1][key1].value = rows?.[idx1]
      ?.slice(0)
      ?.reverse()
      ?.slice(0, middleColumnIdx)
      ?.find((elem) => elem.formattedValue)?.formattedValue;
    (viewData as any).players[0][key2].value = rows?.[idx2]
      ?.slice(0, middleColumnIdx)
      ?.find((elem) => elem.formattedValue)?.formattedValue;
    (viewData as any).players[1][key2].value = rows?.[idx2]
      ?.slice(0)
      ?.reverse()
      ?.slice(0, middleColumnIdx)
      ?.find((elem) => elem.formattedValue)?.formattedValue;
    (viewData as any).players[0][key3].value = rows?.[idx3]
      ?.slice(0, middleColumnIdx)
      ?.find((elem) => elem.formattedValue)?.formattedValue;
    (viewData as any).players[1][key3].value = rows?.[idx3]
      ?.slice(0)
      ?.reverse()
      ?.slice(0, middleColumnIdx)
      ?.find((elem) => elem.formattedValue)?.formattedValue;
  }

  console.log({ viewData });

  const view = (
    <Frame>
      <table style={{ display: "block", width: "auto", }}>
        <tbody>
          <tr className="bg-grey">
            {Object.keys(viewData.players[0]).map((val, idx) => {
              const player1 = viewData.players[0] as any;
              const playerProp = player1[val];
              return (
                <td
                  style={{ padding: '4px 20px', }}
                  key={`playerProps${idx}`}
                >
                  {playerProp.header(rows, playerProp, middleColumnIdx, val)}
                </td>
              );
            })}
          </tr>
          {viewData.players.map((data: any, idx) => (
            <tr key={`playerData${idx}`} className="bg-black">
              {Object.keys(data).map((key: string, idx: number) =>
                data[key].render(key, idx, data[key]),
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </Frame>
  );
  return view;
};

export default Stats;
